import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { Col, Row } from "reactstrap";
import { useKeycloak } from "@react-keycloak/web";

import AlertNotification from "../../../../app/src/Components/GUI/Alert/Alert";
import { useDispatch, useSelector } from "react-redux";
import "./Content.css";
import "react-multi-carousel/lib/styles.css";
import {
  turnOffLoadingActionCreator,
  turnOnLoadingActionCreator,
} from "../../../../app/src/actions/commonActions";
import {
  TEXT_NAME_BEGINNING,
} from "../../../CatalogueConstants";
import { getBannersWidgetServices, logUserAnalyticService } from "../../../../app/src/services/services";
import {
  CONTENT_VALUE,
  PAGE_ID_KEY,
  PAGE_VIEW_ACTION,
} from "../../../../app/src/analyticsConstants/analyticsConstants";
import { SORT_TYPES, URL_BASE_IMAGE } from "../../../../app/src/services/servicesConstants";
import ContentComponent from "../Catalogo/components/ContentComponent";
import { URL_VIDEODETAIL } from "../../../../app/src/Components/Routes/Location";
import {getToken} from "../../../../app/src/Utils/utils";

const Content = () => {
  const PAGE_SIZE = 20;
  const [videosCount, setVideosCount] = useState(10);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let { id } = useParams();

  const [videos, setVideos] = useState([]);
  const [sortType, setSortType] = useState(SORT_TYPES['MOST_RECENT_FIRST']);
  const [title, setTitle] = useState('');
  const [error, setError] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [nameOrder, setNameOrder] = useState(t(TEXT_NAME_BEGINNING + "most_recent_first"));
  const [showNoContent, setShowNoContent] = useState(false);
  const [hasMoreVideos, setHasMoreVideos] = useState(true);

  const { keycloak } = useKeycloak();
  const { userData } = useSelector((state) => state.commonState);

  const turnOnLoading = () => {
    dispatch(turnOnLoadingActionCreator());
  };

  const turnOffLoading = () => {
    dispatch(turnOffLoadingActionCreator());
  };

  useEffect(() => {
    const userToken = getToken(keycloak);
    logUserAnalyticService(
      userData.appname,
      userData.username,
      PAGE_VIEW_ACTION,
      PAGE_ID_KEY,
      CONTENT_VALUE,
      userToken
    );

    turnOnLoading();
  }, []);

  const getBannersWidgetCallback = (response) => {
    if(videosCount > response.data.length || response.data.length === 0) {
      setHasMoreVideos(false)
    }

    setTitle(response?.widget?.title)
    let newVideos = [];
    response?.data.forEach(element => {
      let video = {
        id: element.id,
        title: element.title,
        background: {
          id: element?.background,
          url: URL_BASE_IMAGE + element?.background,
        },
        banner: {
          id: element?.banner,
          data: {
            url: URL_BASE_IMAGE + element?.banner,
          }
        },
        poster: {
          id: element?.poster,
          url: URL_BASE_IMAGE + element?.poster,
        },
        score: element?.valoration,
        votes: element?.votes,
        rating: element?.rating?.code,
      };
      newVideos.push(video);
    });
    setVideos(newVideos);
    turnOffLoading();
  }

  const getBannersWidgetErrorCallback = () => {
    setError(true);
  }

  useEffect(() => {
    getBannersWidgetServices(id, videosCount, 1, sortType, getBannersWidgetCallback, getBannersWidgetErrorCallback);
  }, [sortType, videosCount])

  const fetchMoreData = () => {
    setVideosCount(videosCount + PAGE_SIZE);
  };

  const displayMenu = () => {
    if (showMenu) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  };

  const handleClickOrder = (name) => {
    setNameOrder(getTextSort(name));
    setSortType(name);
    setShowMenu(false);
  };

  const getTextSort = (name) => {
    const sortTypeKey = Object.keys(SORT_TYPES).find(key => SORT_TYPES[key] === name);
    return t(TEXT_NAME_BEGINNING + sortTypeKey.toLowerCase());
  };

  useEffect(() => {
    if (videos !== null && videos?.length !== 0) {
      turnOffLoading();
    }
  }, [videos]);

  const handleGoToVideoDetail = (id) => {
    history.push(URL_VIDEODETAIL + "/" + id)
  }

  return (
    <div>
      {videos !== null && (
        <div className="Catalogo Content" id="catalogo">
          <div className="Header">
            <div className="Discover">{title}</div>
          </div>
          <div className="Categories">
            <Row className="InputCatalogue">
              {videos && videos.length !== 0 && (
                <Col xs={12} md={6} className="OrderMenuContainer">
                  <div className="OrderMenu">
                    <div
                      className="MenuCatalogueContainer"
                      onClick={displayMenu}
                    >
                      <span className="MenuCatalogue">{t(nameOrder)}</span>
                      <span className={"IconMenuCatalogue"}>
                        <ion-icon name={"caret-down-outline"} />
                      </span>
                    </div>
                    {showMenu && (
                      <div className="MenuDesplegableOrder">
                        {Object.values(SORT_TYPES).map((value, index) => (
                          <div
                            className="MenuSubsectionOrder"
                            key={index}
                            onClick={() => handleClickOrder(value)}
                          >
                            <span className="button-text">
                              {getTextSort(value)}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </Col>
              )}
            </Row>
          </div>
          {videos && (
            <InfiniteScroll
              dataLength={videos?.length}
              next={fetchMoreData}
              hasMore={hasMoreVideos}
              loader={<h4>Cargando...</h4>}
              scrollableTarget="catalogo">
              <div className="CatalogueVideo">
                <div className="galleryGrid">
                  <ContentComponent contents={videos}
                    handleGoToVideoDetail={handleGoToVideoDetail}
                    changeShowNoContent={setShowNoContent}
                    showNoContent={showNoContent} />
                </div>
              </div>
            </InfiniteScroll>
          )}
        </div>
      )}
      {error && <AlertNotification />}
    </div>
  );
};

export default Content;
