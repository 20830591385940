import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import Player from "./Player";
import "./Player.css";
import WithAuthorization from "../../../../app/src/Utils/WithAuthorization";
import {URL_HOME, URL_LOGIN, URL_PRODUCTS, URL_VIDEODETAIL} from "../../../../app/src/Components/Routes/Location";
import {getContentPermissions} from "../../../../app/src/services/services";
import {MULTITENANT_CONSTANTS, TENANT} from "../../../../app/src/Resources/Multitenant/tenantConstants";
import {getToken} from "../../../../app/src/Utils/utils";
import ErrorMessageModalComponent from "../../../../app/src/Components/common/ErrorMessageModalComponent";
import {turnOffLoadingActionCreator} from "../../../../app/src/actions/commonActions";
import { useDispatch } from "react-redux";

const PlayerPage = (props) => {
  let {id} = useParams();
  const history = useHistory();
  const {scrollRef} = props;
  const [allowed, setAllowed] = useState(undefined);
  const [messageModal, setMessageModal] = useState(false);
  const dispatch = useDispatch();
  const { keycloak, initialized } = useKeycloak();


  const contentPermissionsCallback = (response, contentType) => {
    const allowedResponse = response?.allowed;
    if (allowedResponse !== undefined) {
      setAllowed(allowedResponse);
      if (!allowedResponse) history.push(`${URL_PRODUCTS}/${id}`);
    } else {
      history.push(`${URL_LOGIN}`);
    }
  }

  const turnOffLoading = () => {
    dispatch(turnOffLoadingActionCreator());
  };

  useEffect(() => {
    const userToken = getToken(keycloak);
    console.log('Vamos 0')
    if(!!id) {
      if (initialized && keycloak.authenticated) {
        getContentPermissions(
            TENANT,
            id,
            userToken,
            undefined,
            contentPermissionsCallback
        )
        scrollRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      } else {
        setMessageModal(true);
        turnOffLoading()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const goToVideoDetail = () => {
    if(!!id) {
      history.push(`${URL_VIDEODETAIL}/${id}`);
    } else {
        history.push(`${URL_HOME}`);
    }
  }

  return (
      <>
    <div className="PlayerPage">
      {!!allowed && <Player theme="vjs-theme-forest" id={id} />}
    </div>
        {messageModal && (
            <ErrorMessageModalComponent
                textTitle="error.modal.common.title"
                textBody="error.modal.rating.message"
                textButton="user.login"
                action={() => keycloak.login({ locale: MULTITENANT_CONSTANTS[TENANT]?.defaultLocale })}
                onClose={() => {setMessageModal(false); goToVideoDetail();}}
            />
        )}
        </>
  );
};

export default WithAuthorization(PlayerPage);
